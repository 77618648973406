<template>
  <div id="app">
    <div class="horizontal-line">
      <div class="logo-circle"></div>
    </div>
    <LogoCircle />
    <div class="content-wrapper">
      <div class="content-side content-left">
        <div class="content-block">
          <div
            class="mobile-img-wrapper"
            @click="openLink(furnitureSite)"
          ></div>
          <img
            @click="openLink(furnitureSite)"
            src="@/assets/furniture.jpg"
            alt="Furniture"
            class="content-image"
          />
          <div class="text-link-wrapper">
            <h2 class="content-header" @click="openLink(furnitureSite)">
              {{ $t('message.furnitureText') }}
              <br />
              <span class="content-header__name">{{
                $t('message.furnitureTitle')
              }}</span>
            </h2>
            <a :href="furnitureSite" class="link-button" target="_blank">
              {{ $t('message.goto') }}
              <LinkIcon class="link-icon" />
            </a>
          </div>
        </div>
      </div>
      <div class="content-separator"></div>
      <div class="content-side content-right">
        <div class="content-block">
          <img
            @click="openLink(buildingSite)"
            src="@/assets/building.jpg"
            alt="Building"
            class="content-image"
          />
          <div class="mobile-img-wrapper" @click="openLink(buildingSite)"></div>
          <div class="text-link-wrapper">
            <h2 class="content-header" @click="openLink(buildingSite)">
              {{ $t('message.buildingText') }}
              <br />
              <span class="content-header__name">{{
                $t('message.buildingTitle')
              }}</span>
            </h2>
            <a :href="buildingSite" class="link-button" target="_blank">
              {{ $t('message.goto') }}
              <LinkIcon class="link-icon" />
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LogoCircle from '@/components/LogoCircle'
import LinkIcon from '@/components/LinkIcon'

export default {
  name: 'App',
  components: { LinkIcon, LogoCircle },
  data() {
    return {
      furnitureSite: 'https://doichman-mebel.com.ua/',
      buildingSite: 'https://doichman-house.com/'
    }
  },
  created() {
    document.title = String(this.$t('message.title'))
  },
  methods: {
    openLink(link) {
      if (!link) return
      window.open(link, '_blank')
    }
  }
}
</script>

<style lang="scss">
@import '~normalize.css';
@import url('https://fonts.googleapis.com/css?family=Raleway:300,400,500');

*,
*::after,
*::before {
  box-sizing: border-box;
}

html,
body {
  width: 100%;
  height: 100%;
  font-family: Raleway, Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>

<style scoped lang="scss">
$logo-size: 130px;
$logo-half: $logo-size / 2;

#app {
  display: flex;
  width: 100%;
  height: 100%;
}
.logo {
  z-index: 10;
  position: absolute;
  width: $logo-size;
  height: $logo-size;
  top: calc(50% - #{$logo-half + 18px});
  right: calc(50% - #{$logo-half});
  pointer-events: none;
}
.horizontal-line {
  position: absolute;
  z-index: -1;
  top: 25%;
  width: 100%;
  height: 50%;
  background-color: #d9d9d9;
}
.content-wrapper {
  display: flex;
  width: 100%;
  padding: 0 5%;
}
.content-separator {
  flex: 1 1;
  max-width: 350px;
  min-width: 250px;
}
.content-side {
  flex: 1 1;
  margin: auto 0;
  &.content-left {
    padding-bottom: 10%;
    .content-block .content-image {
      margin: 0 0 16px 0;
    }
  }
  &.content-right {
    padding-top: 10%;
    .content-block {
      .content-image {
        margin: 16px 0 0 0;
      }
      flex-flow: column-reverse;
    }
  }
}
.content-block {
  display: flex;
  flex-flow: column;
}
.content-image {
  width: 100%;
  border-radius: 8px;
  user-select: none;
  -webkit-user-drag: none;
  cursor: pointer;
}
.text-link-wrapper {
  display: flex;
  flex-flow: column;
}
.content-header {
  cursor: pointer;
  margin: 0;
  font-size: 20px;
  font-weight: 400;
  line-height: 26px;
  .content-header__name {
    font-weight: 500;
  }
}
.link-button {
  color: #000;
  border: 1px solid #000;
  display: flex;
  padding: 4px 8px;
  margin: 16px auto 0 0;
  border-radius: 4px;
  font-size: 14px;
  line-height: 16px;
  text-decoration: none;
}
.link-icon {
  max-width: 8px;
  max-height: 8px;
  margin-left: 3px;
}

.mobile-img-wrapper,
.logo-circle {
  display: none;
}

@media only screen and (max-width: 767px) {
  .horizontal-line {
    z-index: 10;
    height: 30px;
    top: calc(50% - 15px);
    background-color: #fff;
  }
  .logo-circle {
    position: relative;
    $circle-diameter: 170px;
    top: calc(50% - #{$circle-diameter / 2});
    left: calc(50% - #{$circle-diameter / 2});
    width: $circle-diameter;
    height: $circle-diameter;
    display: block;
    background-color: #fff;
    border-radius: 50%;
  }
  .logo {
    z-index: 11;
    top: calc(50% - #{$logo-half});
  }
  .content-wrapper {
    flex-flow: column;
    padding: 0;
  }
  .content-separator {
    display: none;
  }
  .content-image {
    border-radius: unset;
  }
  .content-side {
    flex: unset;
    height: 50%;
  }
  .content-side.content-left,
  .content-side.content-right {
    padding: 0;
    flex-flow: unset;
    .content-block .content-image {
      margin: 0;
    }
  }
  .content-side {
    background-size: cover;
    background-repeat: no-repeat;
    &.content-left {
      background-image: url('./assets/furniture.jpg');
      background-position: 70% 0;
    }
    &.content-right {
      background-image: url('./assets/building.jpg');
      background-position: 55% 0;
    }
  }
  .content-image {
    display: none;
  }
  .content-header {
    font-size: 16px;
    line-height: 21px;
    color: #fff;
  }
  .content-block {
    position: relative;
    height: 100%;
  }
  .text-link-wrapper {
    z-index: 1;
    width: 100%;
    display: flex;
    flex-flow: row;
    align-items: center;
    padding: 12px 16px;
    background-color: rgba(#000, 0.7);
  }
  .link-button {
    margin: 0 0 0 auto;
    font-size: 12px;
    line-height: unset;
    color: #fff;
    border-color: #fff;
  }
  .mobile-img-wrapper {
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    cursor: pointer;
  }
}
</style>
