<template>
  <div class="logo">
    <img
      class="logo__border"
      src="@/assets/logo-border.svg"
      alt="Logo border"
    />
    <img
      class="logo__center"
      src="@/assets/logo-center.svg"
      alt="Logo center"
    />
    <img class="logo__name" src="@/assets/logo-name.svg" alt="Logo name" />
  </div>
</template>

<script>
export default {
  name: 'LogoCircle'
}
</script>

<style scoped lang="scss">
@keyframes rotateLogo {
  from {
    transform: rotate(0deg);
    opacity: 0;
  }
  60% {
    opacity: 1;
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes appearLogo {
  from {
    transform: translateY(-30px);
    opacity: 0;
  }
  67% {
    opacity: 1;
  }
  to {
    transform: translateY(0);
  }
}
@keyframes appearLogoName {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.logo {
  position: relative;
  width: 130px;
  height: 130px;
}
.logo__border {
  position: absolute;
  top: 0;
  left: 0;
  max-width: 100%;
  max-height: 100%;
  user-select: none;
  -webkit-user-drag: none;
  animation-duration: 3.2s;
  animation-timing-function: ease-out;
  animation-name: rotateLogo;
  animation-iteration-count: 1;
}
.logo__center {
  position: absolute;
  max-width: 36%;
  max-height: 50%;
  left: 35.5%;
  top: 23%;
  user-select: none;
  -webkit-user-drag: none;
  animation-duration: 3s;
  animation-timing-function: ease;
  animation-name: appearLogo;
  animation-iteration-count: 1;
}
.logo__name {
  position: absolute;
  top: 140px;
  animation-duration: 2s;
  animation-timing-function: ease;
  animation-name: appearLogoName;
  animation-iteration-count: 1;
}

@media only screen and (max-width: 767px) {
  .logo__name {
    display: none;
  }
}
</style>
