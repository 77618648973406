<template>
  <svg
    width="8"
    height="8"
    viewBox="0 0 8 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.25 7.25H0.75V1.625H3.75V1.125H0.25V7.75H6.75V4.25H6.25V7.25Z"
      class="link-icon-svg-path"
    />
    <path
      d="M4.75001 0.25V0.75H6.89646L2.94824 4.69823L3.30177 5.05177L7.25001 1.10355V3.25H7.75001V0.25H4.75001Z"
      class="link-icon-svg-path"
    />
  </svg>
</template>

<script>
export default {
  name: 'LinkIcon'
}
</script>

<style scoped lang="scss">
.link-icon-svg-path {
  fill: black;
}

@media only screen and (max-width: 767px) {
  .link-icon-svg-path {
    fill: white;
  }
}
</style>
