import Vue from 'vue'
import App from './App.vue'
import VueI18n from 'vue-i18n'

Vue.use(VueI18n)
Vue.config.productionTip = false

const messages = {
  en: {
    message: {
      title: 'Doichman Hub',
      goto: 'Go to site',
      furnitureText: 'Furniture Manufacturer',
      furnitureTitle: 'Doichman Furniture',
      buildingText: 'Construction company',
      buildingTitle: 'Doichman House'
    }
  },
  ru: {
    message: {
      title: 'Дойчман Центр',
      goto: 'Открыть сайт',
      furnitureText: 'Фабрика мебели под заказ',
      furnitureTitle: 'Дойчман Мебель',
      buildingText: 'Строительная компания',
      buildingTitle: 'Дойчман House'
    }
  },
  uk: {
    message: {
      title: 'Дойчман Центр',
      goto: 'Відкрити сайт',
      furnitureText: 'Фабрика меблів на замовлення',
      furnitureTitle: 'Дойчман Меблі',
      buildingText: 'Будівельна компанія',
      buildingTitle: 'Дойчман House'
    }
  }
}

const i18n = new VueI18n({
  locale: navigator.language,
  fallbackLocale: 'ru',
  messages
})

new Vue({
  render: h => h(App),
  i18n
}).$mount('#app')
